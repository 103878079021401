<template>
  <div v-if="organization" class="config-card">
    <el-row>
      <el-col :span="7">
        <div
          style="height: 200px; display:flex; justify-content: center; align-items: center"
        >
          <app-logo />
        </div>
      </el-col>

      <el-col :span="16">
        <div
          style="display:flex; justify-content: space-between; align-items: baseline"
        >
          <h2 class="mb-1">{{ organization.name }}</h2>
          <el-button size="small" type="success" @click="handleEdit">
            <i class="el-icon-edit mr-1"></i>
            {{ labels.LBL_EDIT }}
          </el-button>
        </div>
        <p style="font-size: 0.9rem">Estd. {{ organization.establishment }}</p>

        <el-row type="flex">
          <el-col :span="12">
            <p style="font-size: 0.9rem">{{ organization.address1 }}</p>
            <p style="font-size: 0.9rem">{{ organization.address2 }}</p>
            <p style="font-size: 0.9rem">{{ organization.city }}, Canada</p>
          </el-col>

          <el-col :span="12">
            <br />
            <p>{{ organization.phone }}</p>
            <p>{{ organization.email }}</p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="mt-5" :gutter="10">
      <h3 class="ml-4">{{ labels.LBL_SOCIAL_LINKS }}</h3>
      <el-col :span="6" class="social-media">
        <div
          class="social-media__name"
          :class="[!organization.facebook_url && 'disabled']"
        >
          <IconBase icon-name="Facebook" class="social-media__icon">
            <IconFb></IconFb>
          </IconBase>
          {{ labels.LBL_FB }}
        </div>

        <a
          :href="organization.facebook_url"
          target="_blank"
          class="social-media__link"
        >
          {{ organization.facebook_url }}
        </a>
      </el-col>

      <el-col :span="6" class="social-media">
        <div
          class="social-media__name"
          :class="[!organization.youtube_url && 'disabled']"
        >
          <IconBase icon-name="Youtube" class="social-media__icon">
            <IconYoutube></IconYoutube>
          </IconBase>
          {{ labels.LBL_YT }}
        </div>

        <a
          :href="organization.youtube_url"
          target="_blank"
          class="social-media__link"
        >
          {{ organization.youtube_url }}
        </a>
      </el-col>

      <el-col :span="6" class="social-media">
        <div
          class="social-media__name"
          :class="[!organization.linkedin_url && 'disabled']"
        >
          <IconBase icon-name="Linkedin" class="social-media__icon">
            <IconLinkedin></IconLinkedin>
          </IconBase>
          {{ labels.LBL_LNKIN }}
        </div>

        <a
          :href="organization.linkedin_url"
          target="_blank"
          class="social-media__link"
        >
          {{ organization.linkedin_url }}
        </a>
      </el-col>

      <el-col
        :span="6"
        class="social-media"
        :class="[!organization.twitter_url && 'disabled']"
      >
        <div class="social-media__name">
          <IconBase icon-name="Twitter" class="social-media__icon">
            <IconTwitter></IconTwitter>
          </IconBase>
          {{ labels.LBL_TWI }}
        </div>

        <a
          :href="organization.twitter_url"
          target="_blank"
          class="social-media__link"
        >
          {{ organization.twitter_url }}
        </a>
      </el-col>

      <el-col
        :span="6"
        class="social-media"
        :class="[!organization.instagram_url && 'disabled']"
      >
        <div class="social-media__name">
          <IconBase icon-name="Instagram" class="social-media__icon">
            <IconInsta></IconInsta>
          </IconBase>
          {{ labels.LBL_INSTA }}
        </div>
        <a
          :href="organization.instagram_url"
          target="_blank"
          class="social-media__link"
        >
          {{ organization.instagram_url }}
        </a>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";

  // import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import IconBase from "@/components/IconBase";
  import IconFb from "@/components/icons/IconFb";
  import IconTwitter from "@/components/icons/IconTwitter";
  import IconInsta from "@/components/icons/IconInsta";
  import IconYoutube from "@/components/icons/IconYoutube";
  import IconLinkedin from "@/components/icons/IconLinkedin";
  import AppLogo from "../../../components/AppLogo.vue";

  export default {
    name: "CompanyDetail",

    components: {
      IconBase,
      IconFb,
      IconTwitter,
      IconInsta,
      IconYoutube,
      IconLinkedin,
      AppLogo,
    },

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapState({ organization: (state) => state.organization.orgDetail }),
    },

    methods: {
      handleEdit() {
        this.$router.push({ name: "Edit Company Detail" });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main";

  .config-card {
    // padding: 1rem;
    background-color: white;

    .social-media {
      height: 30px;
      margin: 1rem;
      text-align: center;
      word-break: break-all;

      &__icon {
        height: 20px;
        width: 20px;
        margin-right: 0.5rem;
      }

      &__name {
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__link {
        font-size: 0.85rem;
        color: gray;
        @include line-clamp(1);
      }
    }
  }
</style>
